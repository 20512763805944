import { Navigate } from "react-router-dom";
import { getLocalAccessToken } from "../utils/constants";
import { AuthContext } from "../context/AuthContext";
import { useContext } from "react";
import MainLayout from "./MainLayout";
import React from "react";
const PrivateRoute = ({ children }) => {
    const {authState} = useContext(AuthContext)

  const accessToken = getLocalAccessToken();
  const auth = !!(authState.isAuthenticated && accessToken);
  return <>{auth ? children : <Navigate to="/login" />}</>;
};

export default PrivateRoute;
