// src/components/Layout.js
import React, { useState } from 'react';
import Sidebar from './SideBar';

const Layout = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className="flex h-screen">
      <Sidebar isOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      <div className="flex-grow flex flex-col">
        <header className="bg-blue-500 text-white p-4 flex justify-between items-center lg:hidden">
          <button onClick={toggleSidebar}>
            <i className="fas fa-bars"></i> {/* Hamburger Icon */}
          </button>
          {/* <h1 className="text-xl font-bold">HealthOrb</h1> */}
        </header>
        <main className="p-4 flex-1 bg-gray-100">
          {children}
        </main>
      </div>
    </div>
  );
};

export default Layout;
