import { getLocalAccessToken } from "../utils/constants";

export const fetchNotes = async (visit_date) => {
  const accessToken = getLocalAccessToken()
  try {
    const response = await fetch(`https://api.saasprotech.com/visits?visit_date=${visit_date}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}` 
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data.notes || [];  
  } catch (error) {
    console.error('Error fetching notes:', error);
    return [];  
  }
};
