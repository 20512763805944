import React from "react";
import { Navigate } from "react-router-dom";
import { getLocalAccessToken } from "../utils/constants";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
const PublicRoute = ({ children }) => {
    const {authState} = useContext(AuthContext)
  const accessToken = getLocalAccessToken();
  const auth = !!(authState.isAuthenticated&& accessToken);
  return auth ? <Navigate to="/dashboard" /> : children;
};

export default PublicRoute;
