// src/components/MainLayout.js
import React from 'react';
import Layout from './Layout';
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import UserProfile from './UserProfile'; // Import the UserProfile component

const MainLayout = ({ children }) => {
  const { logout } = useContext(AuthContext);

  return (
    <Layout>
      <div className="flex justify-between items-center p-8 relative">
        <div className="flex-1">
          {children}
        </div>
        <div className="absolute top-1 right-5">
          <UserProfile /> {/* Add the UserProfile component here */}
        </div>
        {/* Uncomment if you have a logout button */}
        {/* <button
          onClick={() => logout()}
          className="mt-auto py-2 px-4 bg-red-600 text-white rounded hover:bg-red-700">
          Logout
        </button> */}
      </div>
    </Layout>
  );
};

export default MainLayout;
