import React, { useState, useEffect } from 'react';
import { FaUserCircle } from 'react-icons/fa';

const UserProfile = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch('https://api.saasprotech.com/users/me', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setUser(data);
        } else {
          //delete localStorage.getItem('accessToken');
          // push to login
          localStorage.removeItem('accessToken');
          localStorage.removeItem('userRole');
          window.location.href = '/login';
          console.error('Failed to fetch user details');
        }
      } catch (error) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('userRole');
        window.location.href = '/login';
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, []);

  return (
    <div className="flex items-center space-x-2">
      <FaUserCircle className="text-3xl text-gray-600" />
      {user && (
        <span className="text-gray-700 font-semibold">
          {user.fullName}
        </span>
      )}
    </div>
  );
};

export default UserProfile;
