import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    userRole: null,
  });

  const [currentPassword, setCurrentPassword] = useState(null); // Store current password
  const [passwordChangeRequired, setPasswordChangeRequired] = useState(false); // Store flag for password change requirement
  const [session, setSession] = useState(null);
  const [username, setUsername] = useState(null); // Store username

  const logout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userRole');
    setAuthState({
      isAuthenticated: false,
      userRole: null,
    });
    navigate('/login');
  };

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const jwtToken = localStorage.getItem('accessToken');
    const userRole = localStorage.getItem('userRole');

    if (jwtToken && userRole) {
      setAuthState({
        isAuthenticated: true,
        userRole: userRole,
      });
    }
  }, []);

  const login = async (postData) => {
    const url = `https://api.saasprotech.com/auth`;
    try {
      setLoading(true);

      const response = await fetch(url, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      });

      if (response.ok) {
        const responseData = await response.json();
        if (responseData.change_password_url) {
          setCurrentPassword(postData.password); // Store current password
          setPasswordChangeRequired(true); // Set the flag for password change requirement
          setSession(responseData.session);
          setUsername(postData.username); // Store username
          navigate('/change-password');
        } else {
          const jwtToken = responseData.access_token;
          localStorage.setItem('accessToken', jwtToken);
          localStorage.setItem('userRole', responseData.userRole); // Store user role
          setAuthState({
            isAuthenticated: true,
            userRole: responseData.userRole,
          });
          navigate("/dashboard");
        }
      } else {
        // toast.error('Invalid credentials');
      }
    } catch (error) {
      console.error('Error logging in:', error);
      // toast.error('An error occurred while logging in');
    } finally {
      setLoading(false);
    }
  };

  const changePassword = async ({ newPassword }) => {
    const url = `https://api.saasprotech.com/auth/change-password`;
    try {
      setLoading(true);
  
      const response = await fetch(url, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password: currentPassword, new_password: newPassword, session, username }),
      });
  
      if (response.ok) {
        const responseData = await response.json();
        const jwtToken = responseData.access_token;
        localStorage.setItem('accessToken', jwtToken);
        localStorage.setItem('userRole', responseData.userRole); // Ensure userRole is updated
        setAuthState({
          isAuthenticated: true,
          userRole: responseData.userRole,
        });
        setPasswordChangeRequired(false); // Reset the flag after password change
        navigate("/dashboard"); // Navigate to dashboard
      } else {
        console.error('Error changing password');
      }
    } catch (error) {
      console.error('Error changing password:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthContext.Provider value={{ authState, login, loading, logout, changePassword, passwordChangeRequired }}>
      {children}
    </AuthContext.Provider>
  );
};
